import Image from 'next/image';
import FooterTwo from "../../common/elements/footer/FooterTwo";
import HeaderFive from '../../common/elements/header/HeaderFive';
import HeadTitle from "../../common/elements/head/HeadTitle";

const Error404 = () => {
  const seo = {
    title: 'Pose',
  }
  return (
    <>
      <HeadTitle seo={seo} />
      <HeaderFive />
      <div className="error-area bg-color-grey" style={{ marginBottom: -40 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <Image
                  width={322}
                  height={131}
                  src="/images/others/404.webp"
                  alt="Error Images"
                />
                <h1 className="title">Không tìm thấy trang!</h1>
                <p>Xin lỗi, chúng tôi không thể tìm thấy trang bạn đang tìm kiếm.</p>
                <div className="back-totop-button cerchio d-inline-block">
                  <a href="/" className="axil-button button-rounded hover-flip-item-wrapper" style={{ display: 'flex', padding: '5px 30px'}}>
                    <span className="hover-flip-item">
                      <span data-text="Trở về trang chủ">Trở về trang chủ</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo bgColor="bg-color-grey" />
    </>
  );
}

export default Error404;