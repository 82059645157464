
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[...not_found]",
      function () {
        return require("private-next-pages/[...not_found]/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[...not_found]"])
      });
    }
  